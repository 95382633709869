<!-- qa搜索模块 -->
<!-- 引入这个模块需要有pagedata -->
<template>
  <div class="searchModelBody">
    <div class="searchBox">
      <h1>RAKUMART{{ $fanyi('帮助中心') }}</h1>
      <div class="searchBoxCon">
        <el-popover
            v-model="visible"
            placement="bottom"
            popper-class="qasearchHistoryBox"
            trigger="manual"
        >
          <ul class="searchHistory">
            <li
                v-for="(item, index) in showSearchList"
                :key="index"
                @click="searchStr = item.text"
            >
              {{ item.text }}
            </li>
          </ul>
          <input
              slot="reference"
              v-model="searchStr"
              :placeholder="$fanyi('请输入你的问题或者关键字')"
              type="text"
              @focus="showSearchList.length ? (visible = true) : ''"
              @focusout="visible = false"
              @input="piPei"
              @keydown.enter.prevent="toResultsPage"
          />
        </el-popover>
        <button @click.prevent="toResultsPage">
          <img :src="require('@/assets/QA/search.png')" alt=""/>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchStr: "",
      visible: false, //是否显示搜索列表弹出框
      showSearchList: [],
    };
  },
  components: {},
  created() {
    // 假如路由带有s参数代表在搜索页面,显示正在搜索的关键词
    if (this.$route.query.s) {
      this.searchStr = this.$route.query.s.replace("+", " ");
    }
  },
  methods: {
    // 输入字符时匹配搜索历史
    piPei() {
      this.showSearchList = [];
      this.$store.state.QASearchHistore.forEach((element) => {
        if (element.text.indexOf(this.searchStr.trim()) != -1) {
          this.showSearchList.unshift(element);
        }
      });
      this.showSearchList = this.showSearchList.slice(0, 6);
      this.visible = false;
      setTimeout(() => {
        if (this.showSearchList.length) {
          this.visible = true;
        }
      }, 100);
    },
    // 跳转搜索结果页面
    toResultsPage() {
      // 如果没有重复的就存起来
      if (
          (this.$store.state.QASearchHistore,
              !this.$store.state.QASearchHistore.find((item) => {
                return item.text == this.searchStr.trim();
              }))
      ) {
        // 把搜索的词语存起来给搜索历史模块使用
        this.$store.commit(
            "getQASearchHistore",
            this.$store.state.QASearchHistore.unshift({
              text: this.searchStr.trim(),
            })
        );
      }
      // 把搜索字符串里的空格转换为加号(因为示例网站有这样转换)
      let searchStrJia = this.searchStr.trim().replace(" ", "+");
      this.$fun.routerToPage(`/answerSearchResultPage?keyword=${searchStrJia}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/mixin.scss";

.searchModelBody {
  margin-top: -20px;
  width: 100%;
  height: 292px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f6f7;

  .searchBox {
    h1 {
      text-align: center;
      padding-bottom: 40px;
      line-height: 1;
      font-size: 30px;
      font-weight: bold;
    }

    .searchBoxCon {
      display: flex;
      position: relative;

      input {
        width: 700px;
        background-color: white;
        flex: 1;
        height: 64px;
        border: none;
        padding: 0 86px 0 21px;
        font-size: 15px;
        transform: 0.4s;
        border-radius: 32px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

        &:focus {
          box-shadow: 0px 3px 12px -1px rgba(0, 0, 0, 0.14);
        }
      }

      .clearInput {
        position: absolute;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        cursor: pointer;
      }

      &:hover .clearInput {
        display: block;
      }

      button {
        position: absolute;
        right: 0;
        height: 100%;
        width: 65px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 17px;
          height: 18px;
        }
      }
    }
  }

  .el-popover {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 32px;
  }
}
</style>
<style lang="scss">
.qasearchHistoryBox {
  padding: 0;

  .searchHistory {
    min-width: 110px;
    max-width: 500px;

    li {
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #e8e8e9;
      }
    }
  }
}
</style>
